import React from 'react'
// Imports from gatsby.
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { ExameDetalhe } from '@monorepo-amais/commons/components/exames'
import SEO from '../components/seo'

export default function ExamesTemplate({ pageContext, data }) {
	const { exames, cosmicjsInformacoesEstaticas } = data // There is also 'site' object in data.

	exames.infoEstat = cosmicjsInformacoesEstaticas

	// TODO: colocar o babel pra tirar os console.logs

	const seoTitle = `Exame de ${exames.nome}`
	const seoDescription =
		`Realize o exame de ${exames.nome} ` +
		'em uma das unidades Labs a+. Faça agora o agendamento do seu Exame. ' +
		'Temos agenda para todos os dias da semana!'
	return (
		<>
			<SEO title={seoTitle} description={seoDescription} />
			<ExameDetalhe
				{...exames}
				Layout={Layout}
				// TODO trocar para pegar a informacao do cosmic
				// urlChat={'https://xwc-grupofleury.xgen.com.br/fleury/form_chat_amais.html'}
			/>
		</>
	)
}

export const pageQuery = graphql`
	query($slug: String!) {
		cosmicjsInformacoesEstaticas(title: { eq: "Detalhe Exames" }) {
			id
			metadata {
				informacoes
				telefone
				informacoes_adicionais {
					titulo_card
					informacoes_card
					texto_botao_card
					acao_botao_card
				}
			}
		}
		exames(slug: { eq: $slug }) {
			id
			idLegado
			slug
			nome
			sinonimias
			precisaAgendar
			instrucoesGerais {
				titulo
				conteudo
			}
			unidades {
				id
				idLegado
				nome
				title
				slug
				endereco
				bairro
				cep
				cidade
				estado
				thumbnail {
					imagem {
						url
						imgix_url
					}
				}
			}
		}
	}
`
